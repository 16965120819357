'use client'

import * as React from 'react'

export interface NotificationSettingsDialogContextModel {
  isOpen: boolean
  openDialog: () => void
  closeDialog: () => void
}

const NotificationSettingsDialogContext =
  React.createContext<NotificationSettingsDialogContextModel>(null!)

export const NotificationSettingsDialogProvider = ({ children }: React.PropsWithChildren) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const openDialog = () => {
    setIsOpen(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  return (
    <NotificationSettingsDialogContext.Provider value={{ isOpen, closeDialog, openDialog }}>
      {children}
    </NotificationSettingsDialogContext.Provider>
  )
}

export const useNotificationSettingsDialog = () => {
  const context = React.useContext(NotificationSettingsDialogContext)

  if (context === undefined) {
    throw new Error(
      'useNotificationSettingsDialog must be used within a NotificationSettingsDialogProvider',
    )
  }

  return context
}
