import {
  AwardStarIcon,
  ChatIcon,
  QuestionMarkIcon,
  RssFeedIcon,
  StarCircleIcon,
  StylusIcon,
  ThumbUpIcon,
} from '@ask-cf/assets/icons'

type IconProps = React.ComponentProps<'svg'>

export enum NotificationType {
  NEW_ANSWER = 'NEW_ANSWER',
  NEW_FOLLOW = 'NEW_FOLLOW',
  BEST_ANSWER = 'BEST_ANSWER',
  NEW_COMMENT = 'NEW_COMMENT',
  NEW_LIKE_ANSWER = 'NEW_LIKE_ANSWER',
  COIN_TIP = 'COIN_TIP',
  NEW_LIKE_COMMENT = 'NEW_LIKE_COMMENT',
  NEW_COMMENT_ON_COMMENT = 'NEW_COMMENT_ON_COMMENT',
  NEW_ANSWER_ON_FOLLOWED_QUESTION = 'NEW_ANSWER_ON_FOLLOWED_QUESTION',
  NEW_QUESTION_FROM_FOLLOWED_CRAFTER = 'NEW_QUESTION_FROM_FOLLOWED_CRAFTER',
}

export interface NotificationTypeData {
  translationKey: string
  color: string
  fontColor: string
  notificationIcon: React.FC<IconProps>
}

export const notificationTypes: { [key in NotificationType]: NotificationTypeData } = {
  [NotificationType.NEW_ANSWER]: {
    translationKey: 'new_answer',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: StylusIcon,
  },
  [NotificationType.NEW_FOLLOW]: {
    translationKey: 'new_follow',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: RssFeedIcon,
  },
  [NotificationType.BEST_ANSWER]: {
    translationKey: 'best_answer',
    color: '#FAE6E9',
    fontColor: '#FF7083',
    notificationIcon: AwardStarIcon,
  },
  [NotificationType.NEW_COMMENT]: {
    translationKey: 'new_comment',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: ChatIcon,
  },
  [NotificationType.NEW_LIKE_ANSWER]: {
    translationKey: 'new_like_answer',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: ThumbUpIcon,
  },
  [NotificationType.COIN_TIP]: {
    translationKey: 'coin_tip',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: StarCircleIcon,
  },
  [NotificationType.NEW_LIKE_COMMENT]: {
    translationKey: 'new_like_comment',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: ThumbUpIcon,
  },
  [NotificationType.NEW_COMMENT_ON_COMMENT]: {
    translationKey: 'new_comment_on_comment',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: ChatIcon,
  },
  [NotificationType.NEW_ANSWER_ON_FOLLOWED_QUESTION]: {
    translationKey: 'new_answer_on_followed_question',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: RssFeedIcon,
  },
  [NotificationType.NEW_QUESTION_FROM_FOLLOWED_CRAFTER]: {
    translationKey: 'new_question_from_followed_crafter',
    color: '#E3EAFC',
    fontColor: '#2A54D1',
    notificationIcon: QuestionMarkIcon,
  },
}
