import { NotificationType } from '@ask-cf/utils/notifications'
import Avatar1 from '@ask-cf/assets/images/avatar1.webp'
import Avatar2 from '@ask-cf/assets/images/avatar2.webp'
import Avatar4 from '@ask-cf/assets/images/avatar4.webp'
import Avatar6 from '@ask-cf/assets/images/avatar6.webp'
import Avatar8 from '@ask-cf/assets/images/avatar8.webp'

export interface NotificationModel {
  type: NotificationType
  isRead: boolean
  users: {
    id: string
    name: string
    avatarUrl: string
  }[]
  createdAt: Date
  questionSlug: string
  topicSlug: string
}

const testUser = {
  id: '1',
  name: 'Edward Curr',
  avatarUrl: Avatar1.src,
}

const multipleTestUsers = [
  {
    id: '2',
    name: 'John Doe',
    avatarUrl: Avatar2.src,
  },
  {
    id: '3',
    name: 'Jane Doe',
    avatarUrl: Avatar4.src,
  },
  {
    id: '4',
    name: 'Alice Doe',
    avatarUrl: Avatar6.src,
  },
  {
    id: '5',
    name: 'Bob Doe',
    avatarUrl: Avatar8.src,
  },
  {
    id: '6',
    name: 'John Doe',
    avatarUrl: Avatar2.src,
  },
  {
    id: '7',
    name: 'Jane Doe',
    avatarUrl: Avatar4.src,
  },
  {
    id: '8',
    name: 'Alice Doe',
    avatarUrl: Avatar6.src,
  },
  {
    id: '9',
    name: 'Bob Doe',
    avatarUrl: Avatar8.src,
  },
]

export const testNotifications: NotificationModel[] = [
  {
    type: NotificationType.NEW_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-17'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_FOLLOW,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-25'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.BEST_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-10'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-02'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-07'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: multipleTestUsers,
    createdAt: new Date('2024-04-15'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.COIN_TIP,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-20'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_LIKE_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-18'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_COMMENT_ON_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-30'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_ANSWER_ON_FOLLOWED_QUESTION,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-22'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_QUESTION_FROM_FOLLOWED_CRAFTER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-28'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-05'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_FOLLOW,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-01'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.BEST_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-13'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-21'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-24'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: multipleTestUsers,
    createdAt: new Date('2024-03-31'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.COIN_TIP,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-10'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_LIKE_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-20'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_COMMENT_ON_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-25'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_ANSWER_ON_FOLLOWED_QUESTION,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-04'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_QUESTION_FROM_FOLLOWED_CRAFTER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-08'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-17'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_FOLLOW,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-25'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.BEST_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-10'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-02'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-07'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: multipleTestUsers,
    createdAt: new Date('2024-04-15'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.COIN_TIP,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-20'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_LIKE_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-18'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_COMMENT_ON_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-30'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_ANSWER_ON_FOLLOWED_QUESTION,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-22'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_QUESTION_FROM_FOLLOWED_CRAFTER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-28'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-05'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_FOLLOW,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-01'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.BEST_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-13'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-21'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-03-24'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_LIKE_ANSWER,
    isRead: false,
    users: multipleTestUsers,
    createdAt: new Date('2024-03-31'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.COIN_TIP,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-10'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_LIKE_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-20'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_COMMENT_ON_COMMENT,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-25'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
  {
    type: NotificationType.NEW_ANSWER_ON_FOLLOWED_QUESTION,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-04'),
    questionSlug: 'how-do-i-choose-the-right-font-for-my-project',
    topicSlug: 'fonts',
  },
  {
    type: NotificationType.NEW_QUESTION_FROM_FOLLOWED_CRAFTER,
    isRead: false,
    users: [testUser],
    createdAt: new Date('2024-04-08'),
    questionSlug: 'how-do-i-choose-the-right-adhesive-for-different-paper-crafting-projects',
    topicSlug: 'paper-crafting',
  },
]
